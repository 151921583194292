<template>
  <div class="container" v-if="tableData!=null">
    <el-table stripe :data="tableData" style="width: 100%">
      <el-table-column label="咨询日期" prop="time"></el-table-column>
      <el-table-column label="姓名" prop="name"></el-table-column>
      <el-table-column label="电话号" prop="phone"></el-table-column>
      <el-table-column label="合作区域" prop="area"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column align="right">
        <template slot-scope="scope">
          <div class="mini-btn-container">
            <el-button class="mini-btn" size="mini" @click="detailInfo(scope.row,scope.$index)">详情</el-button>
            <el-button class="mini-btn" size="mini" type="danger" @click="remove(scope.row.uniqid,scope.$index)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 合作申请详情 -->
    <el-dialog class="detail-dialog" title="详细信息" :visible.sync="detail.dialog">
      <div class="list flex align-center"><strong>姓名</strong>{{detail.name}}</div>
      <div class="list flex align-center"><strong>电话</strong>{{detail.phone}}</div>
      <div class="list flex align-center"><strong>合作区域</strong>{{detail.area}}</div>
      <div class="list flex align-center"><strong>销售人员</strong>{{detail.person}}</div>
      <div class="list flex align-center">
        <strong>备注</strong>
        <input class="inp" type="text" maxlength="100" placeholder="点此修改备注(客户意向等）" v-model="detail.remark" @blur="remark(detail.uniqid)" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { loading,msg,confirm } from '../assets/js/util';

export default {
  data() {
    return {
      tableData: null,
      detail: {
        dialog: false
      }
    }
  },
  methods: {
    //合作申请详情
    detailInfo(data,index) {
      const load=loading();
      this.$axios.post('warehouse/customer/detail',{ uniqid: data.uniqid }).then(res => {
        load.close();
        const post_data=res.data;
        this.detail={
          dialog: true,
          index,
          ...data,
          person: post_data.person
        }
      })
    },

    //修改备注
    remark(uniqid) {
      const { index,remark }=this.detail;
      if(remark==this.tableData[index].remark) return;
      this.$axios.post('warehouse/customer/remark',{ uniqid,remark }).then(res => {
        const { data }=res;
        if(data.code==1) {
          this.tableData[index].remark=remark;
          msg('备注成功');
        };
      })
    },

    //移除咨询
    remove(uniqid,index) {
      confirm('提示消息','确定删除该条客户咨询?').then(() => {
        const load=loading();
        this.$axios.post('warehouse/customer/remove',{ uniqid }).then(res => {
          const { data }=res;
          if(data.code==1) {
            this.tableData.splice(index,1);
            msg('删除成功');
          };
          load.close();
        })
      }).catch(() => { })
    }
  },

  created() {
    const load=loading();
    this.$axios.post('warehouse/customer/render').then(res => {
      load.close();
      this.tableData=res.data.customer;
    })
  }
}
</script>

<style scoped>
.container {
  padding: 0 10px 100px 10px;
}

::v-deep .detail-dialog .el-dialog__body {
  padding: 10px 20px;
}

::v-deep .detail-dialog .list {
  font-size: 13px;
  letter-spacing: 0.8px;
  padding-bottom: 15px;
}

.detail-dialog strong {
  display: inline-block;
  width: 80px;
}

.inp {
  color: #606266;
  border: none;
  border-bottom: 1px solid #f1f1f1;
  padding: 6px;
  margin-left: -6px;
  min-width: 260px;
}

.inp:focus-visible {
  outline: 0;
}
</style>