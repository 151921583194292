/*
 * @Description: 
 * @Author: Liang Gaoqiang
 * @Date: 2021-12-14 23:23:51
 */
import 'default-passive-events'
import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/style.css'
import router from './assets/js/router.js'
import Axios from './assets/js/axios.js'
import sound from './assets/sound.wav'
var echarts = require('echarts');

Vue.config.productionTip = false
Vue.prototype.$axios = Axios
Vue.prototype.$echarts = echarts
Vue.prototype.$sound = new Audio(sound)
Vue.use(ElementUI)


import Login from './pages/login.vue';
import Home from './pages/home.vue';
Axios.post('/warehouse/login/is_login').then(res => {
    const is_login = res.data.code == 1 ? true : false;
    Vue.prototype.is_login = is_login;
    document.title = is_login ? '后台管理系统' : '登录-后台管理系统';

    let init_data = { render: h => h(is_login ? Home : Login) };
    if (is_login) {
        init_data.router = router;
    }
    new Vue(init_data).$mount('#app')
})