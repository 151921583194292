/*
 * @Description: 
 * @Author: Liang Gaoqiang
 * @Date: 2021-12-17 00:26:23
 */
import Router from 'vue-router';
import Vue from 'vue';
Vue.use(Router);

import LoginBackground from '../../pages/LoginBackground.vue';
import Person from '../../pages/Person.vue';
import Repass from '../../pages/Repass.vue';
import Recovery from '../../pages/Recovery.vue';
import ProductOrder from '../../pages/ProductOrder.vue';
import Repair from '../../pages/Repair.vue';
import Customer from '../../pages/Customer.vue';

const routes = [
    { path: '/', redirect: '/loginBackground' },
    { path: '/loginBackground', name: 'loginBackground', component: LoginBackground },
    { path: '/person', name: 'person', component: Person },
    { path: '/repass', name: 'repass', component: Repass },
    { path: '/productorder', name: 'productorder', component: ProductOrder },
    { path: '/recovery', name: 'recovery', component: Recovery },
    { path: '/repair', name: 'repair', component: Repair },
    { path: '/customer', name: 'customer', component: Customer }
]

const router = new Router({ routes })

export default router;