<!--
 * @Description: 
 * @Author: Liang Gaoqiang
 * @Date: 2021-12-17 00:31:40
-->
<template>
  <div class="container flex justify-between">
    <div class="list" v-for="(item, index) in imgs" :key="index">
      <img class="img" :src="item" @click="chooseBk(item, index)" />
      <i v-if="index == used_index" class="icon el-icon-success"></i>
    </div>
    <div class="list sizesit" v-for="item in sizesit" :key="'s' + item"></div>
  </div>
</template>

<script>
import util from "../assets/js/util";
export default {
  data() {
    return {
      imgs: [],
      sizesit: 0,
      used_index: 0,
    };
  },
  beforeCreate() {
    const loading=util.loading();
    let cur_bk=localStorage.getItem("bk_url");
    this.$axios.post("/warehouse/login_background/render").then((res) => {
      loading.close();
      const data=res.data;
      if(data.code==1) {
        data.imgs.forEach((e,i) => {
          this.imgs.push(util.ossurl+e+"?x-oss-process=style/suolue");
          if(cur_bk.includes(e)) {
            this.used_index=i;
          }
        });

        //设置占位元素
        const img_line_num=Math.floor(this.$el.clientWidth/225);
        let need_num=img_line_num-(this.imgs.length%img_line_num);
        this.sizesit=need_num>img_line_num-2? 0:need_num;
      }
    });
  },
  methods: {
    chooseBk(url,index) {
      util
        .confirm("提示","确认选取该图片作为登录页面背景？","info")
        .then(() => {
          localStorage.setItem(
            "bk_url",
            url.replace("?x-oss-process=style/suolue","")
          );
          this.used_index=index;
          util.msg("修改成功");
        })
        .catch(() => { });
    },
  },
};
</script>

<style scoped>
.container {
  flex-wrap: wrap;
  margin-left: -5px;
  margin-top: -10px;
}
.list {
  position: relative;
  display: inline-block;
  width: 220px;
  height: 120px;
  margin-left: 5px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  background: #fafafa;
}
.list .icon {
  position: absolute;
  font-size: 18px;
  color: #07c160b5;
  right: 5px;
  bottom: 5px;
}
.list.sizesit {
  background: #fff;
}
.list .img {
  width: 100%;
  height: 100%;
}
</style>